import React from 'react'
import PropTypes from 'prop-types';
import styles from './Hamburger.module.scss'

const Hamburger = (props) => {

  return(
    <button 
      className={`${styles.hamburger} ${(props.isOpen ? styles.active : '')} ${props.className}`} 
      type="button" 
      aria-label={(props.isOpen ? 'Sluit navigatie' : 'Open navigatie')}
      aria-expanded={props.isOpen}
      onClick={props.onClick} ref={props.reference}
    >
      <span className={styles.hamburgerBox}>
        <span className={styles.hamburgerInner}></span>
      </span>
    </button>
  );
}

export default Hamburger;

Hamburger.propTypes = {
  isOpen: PropTypes.bool,
  onClick: PropTypes.func
}