import React from "react"
import { Link } from "gatsby"
import styles from "./Footer.module.scss"
//import vrouwBoksen from './../../../images/graphics/vrouw-boksen.svg';
import snelWandelen from './../../../images/graphics/snelwandelen.svg';
import hnvk from './../../../images/logos/hnvk.svg';

const Footer = () => {
  return (   
    <footer className={styles.footer}>
      <div className={styles.subfooter}>
        <div className="container">
          <img src={hnvk} alt="Hoorn-Noord & Venenlaankwartier" className="my-3" />
          <img src={snelWandelen} alt="" height="350" className={`${styles.boksen} d-none d-sm-block`} />
        </div>
      </div>
      <div className="bg-primary text-white">
        <div className="container py-2 text-center text-sm-left">
          <span className={`mr-2 ${styles.copyright}`}>
            &copy; Gemeente Hoorn
          </span>
          <Link to="/over" className="p-2 text-white d-inline-block">
            Over deze website
          </Link>
          <Link to="/toegankelijkheid" className="p-2 text-white d-inline-block">
            Toegankelijkheid
          </Link>
          <Link to="/contact" className="p-2 text-white d-inline-block">
            Contact
          </Link>
          <Link to="/sitemap" className="p-2 text-white d-inline-block">
            Sitemap
          </Link>
        </div>
      </div>
    </footer>

  );
}

export default Footer;