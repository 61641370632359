import React, { useRef } from 'react'
import PropTypes from "prop-types"
import { useEffect, useState } from 'react'
import { push as Menu } from 'react-burger-menu'
import { Link } from "gatsby"
import { FocusOn } from 'react-focus-on';

import Header from 'components/Global/Header';
import Footer from 'components/Global/Footer';

import "styles/main.scss"

const Layout = ({location, children}) => {
  const [ scrolled, setScrolled ] = useState( false );
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const hamburgerMenuRef = useRef(null);

  let path;

  if(location !== undefined) {
    path = location.pathname;
  }  

  useEffect(_ => {
    const handleScroll = _ => { 
      if (window.pageYOffset > 1) {
        setScrolled(true)
      } else {
        setScrolled(false)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return _ => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const isMenuOpen = (state) => {
    setShowMobileMenu(state.isOpen);
  }

  const MobileMenuLink = ({ to, title, isOpen }) => (
    <Link 
      to={to} 
      className="bm-item py-2" 
      onClick={() => setShowMobileMenu(false)}
      tabIndex={isOpen ? '' : '-1'}
    >
      {title}
    </Link>
  );

  return(
    <>
      <div id="outer-container">
        <Header 
          isFront={path === "/" ? true : false }
          fixed={scrolled ? true : false }
          showMobileMenu={showMobileMenu}
          clickHamburger={() => setShowMobileMenu(showMobileMenu ? false  : true)}
          hamburgerRef={hamburgerMenuRef}
        />
        <FocusOn
          enabled={showMobileMenu}
          shards={[hamburgerMenuRef]}
        >
          <Menu
            isOpen={ showMobileMenu }
            onStateChange={isMenuOpen} 
            customBurgerIcon={ false }
            pageWrapId={ "page-wrap" } 
            outerContainerId={ "outer-container" }
            customCrossIcon={false}
            width={ 300 }
            right
            id="mobile-menu"
          >
            <MobileMenuLink 
              to="/"
              title="Home"
              isOpen={ showMobileMenu }
            />
            <MobileMenuLink 
              to="/routes"
              title="Routes"
              isOpen={ showMobileMenu }
            />
            <MobileMenuLink 
              to="/oefeningen"
              title="Oefeningen"
              isOpen={ showMobileMenu }
            />
            <MobileMenuLink 
              to="/organisaties"
              title="Organisaties"
              isOpen={ showMobileMenu }
            />
            <MobileMenuLink 
              to="/sparen"
              title="Sparen"
              isOpen={ showMobileMenu }
            />

          </Menu>
        </FocusOn>
        <main id="page-wrap" className={path === "/" ? 'front' : '' }>
          <div id="page-start"></div>
          {children}
          <Footer />
        </main>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout