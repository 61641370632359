
import React, { useEffect } from 'react'
import { Link } from "gatsby"
import Logo from './../../../images/logos/logo.svg';
import LogoWhite from './../../../images/logos/logo-white.svg';
import styles from "./Header.module.scss"
import Hamburger from './Hamburger';

const Header = ({ isFront = false, fixed = false, showMobileMenu = false, clickHamburger, hamburgerRef }) => {
  //const inputHamburger = useRef(null);

  useEffect(() => {
    if(showMobileMenu === false && hamburgerRef.current) {
      hamburgerRef.current.focus();
    }
  }, [showMobileMenu, hamburgerRef])

  let BrandLogo = <Link to="/" className="navbar-brand my-0 mr-md-auto">
    <img src={(isFront && !fixed) ? LogoWhite : Logo} className={styles.logo} alt="Homepage Beweegroutehoorn.nl" title="Homepage Beweegroutehoorn.nl" />
  </Link>

  if(isFront) {
    BrandLogo = <img src={(isFront && !fixed) ? LogoWhite : Logo} className={styles.logo} alt="Homepage Beweegroutehoorn.nl" title="Homepage Beweegroutehoorn.nl" />
  }
  
  return (
    <header className={`${styles.header} ${fixed ? styles.headerFixed : ''} ${showMobileMenu ? styles.mobileOpen : ''}`}>
      <div className="">
        
        <nav className={`navbar navbar-expand-lg ${(isFront && !fixed) ? 'navbar-dark' : 'navbar-light'}`}>

          <div className="skiplink"><a href="#page-start">Naar hoofdinhoud</a></div>
          
          {BrandLogo}        

          <Hamburger 
            isOpen={showMobileMenu}
            onClick={clickHamburger}
            className={`${(isFront && !fixed) ? 'light' : 'dark'}`}
            reference={hamburgerRef}
          />

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li className={`${styles.navItem} nav-item`}>
                <Link to="/" className="nav-link p-2" activeClassName={styles.active}>
                  Home
                </Link>
              </li>
              <li className={`${styles.navItem} nav-item`}>
                <Link to="/routes" className="nav-link p-2" activeClassName={styles.active}>
                  Routes
                </Link>
              </li>
              <li className={`${styles.navItem} nav-item`}>
                <Link to="/oefeningen" className="nav-link p-2 " activeClassName={styles.active}>
                  Oefeningen
                </Link>
              </li>
              <li className={`${styles.navItem} nav-item`}>
                <Link to="/organisaties" className="nav-link p-2 " activeClassName={styles.active}>
                  Organisaties
                </Link>
              </li>
              <li className={`${styles.navItem} nav-item`}>
                <Link to="/sparen" className="nav-link p-2 " activeClassName={styles.active}>
                  Sparen
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  )
}
export default Header;